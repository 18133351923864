<template>
  <div>
    <FlashMessage :message="message"/>

    <h1>Alle groepen</h1>

    <AdminButton :isAdmin="isAdmin" :isSuperAdmin="isSuperAdmin" />

    <table v-if="GroupResult">
      <tr v-for="group in GroupResult.groups" :key="group.id">
        <td>
          <router-link :to="{ name: 'GroupDetails', params: { id: group.id }}">
           {{ group.name }}
          </router-link>
        </td>
      </tr>
    </table>
  </div>

</template>

<script lang="ts">
import {
  defineComponent,
} from 'vue';
import { useQuery } from 'villus';
import FlashMessage from '../components/FlashMessage.vue';
import AdminButton from '../components/AdminButton.vue';

export default defineComponent({
  name: 'Group',

  components: { AdminButton, FlashMessage },

  props: {
    message: String,
    isAdmin: Boolean,
    isSuperAdmin: Boolean,
  },

  setup() {
    const Group = `
      query {
        groups {
          id
          name
        }
      }
    `;

    const { data: GroupResult } = useQuery(Group);

    return { GroupResult };
  },
});
</script>

<style scoped lang="scss">
table{
  border-collapse:collapse;
}

tr:hover {
  background: #002244;
}

tr:hover a {
  color:white;
}

tr:hover {
  color:white;
  cursor:pointer;
}

</style>
