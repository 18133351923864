
import {
  defineComponent,
} from 'vue';
import { useQuery } from 'villus';
import FlashMessage from '../components/FlashMessage.vue';
import AdminButton from '../components/AdminButton.vue';

export default defineComponent({
  name: 'Group',

  components: { AdminButton, FlashMessage },

  props: {
    message: String,
    isAdmin: Boolean,
    isSuperAdmin: Boolean,
  },

  setup() {
    const Group = `
      query {
        groups {
          id
          name
        }
      }
    `;

    const { data: GroupResult } = useQuery(Group);

    return { GroupResult };
  },
});
